import React, { useState, useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { useSpring, animated } from "react-spring"
import scrollTo from "gatsby-plugin-smoothscroll"
import tw, { styled } from "twin.macro"

import "./hamburger.css"

const Logo = styled.div`
  ${tw`flex items-center justify-center p-5 flex-grow`}
  background: #fff;
  background: #fff;
  padding: 15px 0 0px 0;
  // @media (min-width: 1024px) {
  width: 300px;
  background: #fff;
  background: #cabfab;
  background: #41444b;
  background: #313131;
  // }
  img {
    transition: ease-in-out all;
    &.small {
      width: 150px;
    }
    &.large {
      width: 150px;
      @media (min-width: 1024px) {
        width: 250px;
      }
      transition: ease-in-out all;
    }
  }
  // @media (min-width: 1024px) {
  //   max-width: 25%;
  // }
  // @media (min-width: 1560px) {
  //   max-width: 25%;
  // }
`

const NavHeaderWrap = styled.nav`
  ${tw`flex flex-wrap`}
  .quotey {
    text-decoration: underline;
  }
`

const MenuToggle = styled.div`
  ${tw`z-40 block xl:hidden justify-end absolute xl:relative`}
  background:#313131;
`

const ItemsWrap = styled.div`
  ${tw`hidden w-full flex-shrink xl:flex lg:items-center lg:w-auto`}
  background: #dfd8c8;
  background: #cabfab;
  background: #41444b;
  //background: #313131;
  width: 75% !important;
  @media (max-width: 1560px) {
    width: 75% !important;
  }
  ul {
    ${tw`mt-0 mb-0 lg:flex justify-center lg:flex-grow ml-0`}
    li {
      ${tw`block lg:inline-block mb-0 lg:mt-0 mr-6 text-xs lg:text-base xl:text-lg`}
      color:#ffffff;
      button {
        outline: none;
      }
    }
  }
`
const ItemsWrapMobile = styled.div`
  div {
    ${tw`w-full h-screen bg-white pt-10 xl:hidden w-full flex-grow flex xl:items-center xl:w-auto`}
    position:fixed;
    top: 0;
    left: 0;
  }
  ul {
    ${tw`hidden w-full`}
    margin: 0;
    padding: 0;
    &.show {
      ${tw`block text-sm xl:flex-grow`}
    }
    li {
      ${tw`block mb-10 xl:inline-block text-center text-base`}
    }
  }
`

const DropDown = styled.ul`
  position: absolute;
  display: list-item !important;
  margin-top: 0 !important;
  background: #fff;
  border: 2px solid #41444b;
  .heightAdder {
    height: 20px;
    background: #41444b;
  }
  li {
    display: grid !important;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 2rem;
    margin-bottom: 1rem;
    width: 100%;
    text-align: left;
    a {
      color: #000;
      display: block;
      padding: 10px;
      &:hover {
        background: #23395b;
        background: #dfd8c8;
      }
    }
  }
`

const DropDownMobile = styled.ul`
  display: list-item !important;
  li {
    margin-left: 50px;
    &:first-child {
      margin-top: 20px;
    }
  }
`

const Nav = () => {
  const [{ y }, set] = useSpring(() => ({ y: -1000 }))

  const [show, setShow] = useState(false)
  const [ham, setHam] = useState(false)

  const toggle = () => {
    if (show !== true) {
      set({ y: 0 })
      setHam(true)
      setShow(!show)
    } else {
      set({ y: -1000 })
      setHam(false)

      setTimeout(function() {
        setShow(!show)
      }, 500)
    }
  }

  const [offset, setOffset] = useState(0)
  const [small, setSmall] = useState(false)

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, [])

  useEffect(() => {
    if (offset >= 60) {
      setSmall(true)
    } else {
      setSmall(false)
    }
  }, [offset])

  const [showDrop, setShowDrop] = useState(false)
  const [showDropServices, setShowDropServices] = useState(false)
  const [showDropResources, setShowDropResources] = useState(false)
  const [showDropContact, setShowDropContact] = useState(false)

  const onShowDrop = () => {
    setShowDrop(true)
  }
  const onShowDropMobile = () => {
    setShowDrop(x => !x)
  }
  const onHideDrop = () => {
    setShowDrop(false)
  }
  const onShowDropServices = () => {
    setShowDropServices(true)
  }
  const onShowDropServicesMobile = () => {
    setShowDropServices(x => !x)
  }
  const onHideDropServices = () => {
    setShowDropServices(false)
  }
  const onShowDropResources = () => {
    setShowDropResources(true)
  }
  const onShowDropResourcesMobile = () => {
    setShowDropResources(x => !x)
  }
  const onHideDropResources = () => {
    setShowDropResources(false)
  }
  const onHideDropContact = () => {
    setShowDropContact(false)
  }
  const onShowDropContact = () => {
    setShowDropContact(true)
  }

  return (
    <StaticQuery
      query={graphql`
        query NavAltQuery {
          sanityHeader {
            logoImage {
              asset {
                url
              }
            }
          }
          sanityContact {
            _rawAddress(resolveReferences: { maxDepth: 10 })
          }
          allSanityFlooring {
            edges {
              node {
                _id
                floorType
              }
            }
          }
        }
      `}
      render={data => {
        const dashed_products = data.allSanityFlooring.edges.map(
          ({ node: product }) => {
            let str = product.floorType
            str = str.replace(/\s+/g, "-").toLowerCase()
            return {
              floorType: str,
              _id: product._id,
              floorTypeSpaces: product.floorType,
            }
          }
        ).sort((a, b) => a.floorType.localeCompare(b.floorType));
        return (
          <>
            <NavHeaderWrap>
              <Logo>
                {data.sanityHeader.logoImage.asset && (
                  <Link className="logo" to="/">
                    <img
                      alt="logo"
                      className={small ? "small" : "large"}
                      src={data.sanityHeader.logoImage.asset.url}
                    />
                  </Link>
                )}
              </Logo>
              <MenuToggle onClick={toggle}>
                <button
                  className={
                    !ham
                      ? `hamburger hamburger--collapse`
                      : `hamburger hamburger--collapse is-active`
                  }
                  type="button"
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
              </MenuToggle>
              <ItemsWrap className="ItemsWrap">
                <ul>
                  <li>
                    <Link to="/#about">About</Link>
                  </li>
                  <li>
                    <Link
                      className="menuButton"
                      onMouseOver={onShowDrop}
                      onFocus={onShowDrop}
                      onMouseLeave={onHideDrop}
                      to="/#products"
                    >
                      Products
                      {showDrop && (
                        <DropDown>
                          <div className="heightAdder"></div>
                          {dashed_products.map(product => {
                            return (
                              <li key={product._id}>
                                <Link to={`/products/${product.floorType}`}>
                                  {product.floorTypeSpaces}
                                </Link>
                              </li>
                            )
                          })}
                        </DropDown>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="menuButton"
                      to="/#install"
                      onMouseOver={onShowDropServices}
                      onFocus={onShowDropServices}
                      onMouseLeave={onHideDropServices}
                    >
                      Services
                      {showDropServices && (
                        <DropDown>
                          <div className="heightAdder"></div>
                          {/* <li>
                          <Link to={`/estimates`}>Estimates</Link>
                        </li> */}
                          <li>
                            <Link to={`/on-site-finishing`}>
                              On Site Finishing
                            </Link>
                          </li>
                          <li>
                            <Link to={`/design`}>Design</Link>
                          </li>
                        </DropDown>
                      )}
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/#install"
                      className="menuButton"
                      onClick={() => scrollTo("#install")}
                      onMouseOver={onShowDropResources}
                      onFocus={onShowDropResources}
                      onMouseLeave={onHideDropResources}
                    >
                      Resources
                      {showDropResources && (
                        <DropDown>
                          <div className="heightAdder"></div>
                          {/* <li>
                          <Link to={`/preparation-list`}>preparation List</Link>
                        </li> */}
                          <li>
                            <Link to={`/care-and-maintenance`}>
                              Care and Maintenance
                            </Link>
                          </li>
                          <li>
                            <Link to={`/warranty-policy`}>Warranty Policy</Link>
                          </li>
                        </DropDown>
                      )}
                    </a>
                  </li>
                  <li>
                    <Link className="menuButton" to="/faq">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="menuButton"
                      to="/#contact"
                      onMouseOver={onShowDropContact}
                      onFocus={onShowDropContact}
                      onMouseLeave={onHideDropContact}
                    >
                      Contact
                      {showDropContact && (
                        <DropDown>
                          <div className="heightAdder"></div>
                          <li>
                            <Link to={`/#appointment`}>
                              book an appointment
                            </Link>
                          </li>
                        </DropDown>
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link to="/estimates">Request A Quote</Link>
                  </li>
                  <li>
                    <a href={`tel:${data.sanityContact._rawAddress.phone}`}>
                      {data.sanityContact._rawAddress.phone}
                    </a>
                  </li>
                </ul>
              </ItemsWrap>
              <ItemsWrapMobile className="ItemsWrapMobile">
                <animated.div
                  style={{ transform: y.interpolate(v => `translateY(${v}px`) }}
                >
                  <ul className={show ? "show" : null}>
                    <li>
                      <button
                        className="menuButton"
                        onClick={() => {
                          scrollTo("#about")
                          toggle()
                        }}
                      >
                        About
                      </button>
                    </li>
                    <li>
                      <button className="menuButton" onClick={onShowDropMobile}>
                        Products
                        {showDrop && (
                          <DropDownMobile>
                            {dashed_products.map(product => {
                              return (
                                <li key={product._id}>
                                  <Link to={`/products/${product.floorType}`}>
                                    {product.floorTypeSpaces}
                                  </Link>
                                </li>
                              )
                            })}
                          </DropDownMobile>
                        )}
                      </button>
                    </li>
                    <li>
                      <button
                        className="menuButton"
                        onClick={onShowDropServicesMobile}
                      >
                        Services
                        {showDropServices && (
                          <DropDownMobile>
                            {/* <li>
                            <Link to={`/estimates`}>Estimates</Link>
                          </li> */}
                            <li>
                              <Link to={`/on-site-finishing`}>
                                On Site Finishing
                              </Link>
                            </li>
                            <li>
                              <Link to={`/design`}>Design</Link>
                            </li>
                          </DropDownMobile>
                        )}
                      </button>
                    </li>
                    <li>
                      <button
                        className="menuButton"
                        onClick={onShowDropResourcesMobile}
                      >
                        Resources
                        {showDropResources && (
                          <DropDownMobile>
                            <li>
                              <Link to={`/preparation-list`}>
                                preparation List
                              </Link>
                            </li>
                            <li>
                              <Link to={`/care-and-maintenance`}>
                                Care and Maintenance
                              </Link>
                            </li>
                            <li>
                              <Link to={`/warranty-policy`}>
                                Warranty Policy
                              </Link>
                            </li>
                          </DropDownMobile>
                        )}
                      </button>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/#contact">Contact</Link>
                    </li>
                    <li>
                      <button
                        className="quotey"
                        onClick={() => {
                          scrollTo("#quote")
                          toggle()
                        }}
                      >
                        Request A Quote
                      </button>
                    </li>
                    <li>
                      <a href={`tel:${data.sanityContact._rawAddress.phone}`}>
                        {data.sanityContact._rawAddress.phone}
                      </a>
                    </li>
                  </ul>
                </animated.div>
              </ItemsWrapMobile>
            </NavHeaderWrap>
          </>
        )
      }}
    />
  )
}

export default Nav
